import { filterConstants } from '../constants/filter.constants'

const initialState = {
    parkingFilter: {
        price: [-1, -1],
        width: [-1, -1],
        length: [-1, -1],
        height: [-1, -1],
        startDate: null,
        endDate: null,
        mapStatus: true,
        rowsPerPage: 20,
        page: 0,
        parkingIdHovered: null,
        address: null,
        mensualisation: false,
    },
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case filterConstants.FILTER_ACTION:
            return {
                ...prevState,
                parkingFilter: { ...prevState.parkingFilter, ...action.filter },
            }
        default:
            return prevState
    }
}
