import { userConstants } from '../constants/index'
import { userService } from '../services/user.service'
import { alertActions } from './index'
import { history } from '../helpers/index'
import { requestApi } from '../services/api.service'
import { companiesActions } from './companies/companies.actions'
import { getContextFromUrl } from '../languages/LocalizerUtils'

function refreshToken(dispatch) {
    dispatch({ type: userConstants.REFRESH_TOKEN })
    return userService.refreshToken().then(
        result => {
            dispatch({
                type: userConstants.TOKEN_REFRESH_SUCCESS,
                user: result,
            })
            return result
        },
        error => {
            dispatch({ type: userConstants.TOKEN_REFRESH_FAILURE, error })
        },
    )
}

function init() {
    return dispatch => {
        dispatch({
            type: userConstants.REQUEST_USER,
        })
        const cached = userService.getFromLS()
        if (cached && cached.accessToken) {
            return requestApi('/users', 'GET').then(
                result => {
                    dispatch({
                        type: userConstants.UPDATE_SUCCESS,
                        user: result,
                    })
                    return result
                },
                error => {
                    dispatch({
                        type: userConstants.UPDATE_FAILURE,
                        payload: { error },
                    })
                },
            )
        } else {
            dispatch({
                type: userConstants.UPDATE_FAILURE,
            })
        }
    }
}

function showClientPortail() {
    return requestApi('/users/customer-portal-session', 'GET').then(
        result => {
            return result.url
        },
        error => {
            console.error(error)
        },
    )
}

function login(email, password) {
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_REQUEST })
        return userService.login(email, password).then(
            result => {
                dispatch({
                    type: userConstants.LOGIN_SUCCESS,
                    user: result,
                })
                dispatch(companiesActions.getUserCompany())
            },
            error => {
                console.error('[user] login failed')
                const url = window.location.pathname
                const context = getContextFromUrl(url)
                alert(
                    context === 'en'
                        ? 'Incorrect email or password'
                        : 'Email ou mot de passe incorrect',
                )
                dispatch({ type: userConstants.LOGIN_FAILURE, error })
                dispatch(alertActions.error(error))
                return { success: false, user: null }
            },
        )
    }
}

function logout() {
    return dispatch => {
        userService.logout()
        dispatch({ type: userConstants.LOGOUT, user: null })
    }
}

function signin(body, language, resolve = () => {}, reject = () => {}) {
    body.platformType = { id: 2 }
    return dispatch => {
        dispatch({ type: userConstants.LOGIN_REQUEST, user: { body } })
        return userService.signin(body, language).then(
            async result => {
                if (result?.email && result.email !== '') {
                    await dispatch({
                        type: userConstants.LOGIN_SUCCESS,
                        user: result,
                    })
                    resolve(result)
                } else {
                    await dispatch({ type: userConstants.LOGIN_FAILURE })
                    reject(result)
                }
                return result
            },
            async error => {
                alert(error)
                await dispatch({ type: userConstants.LOGIN_FAILURE, error })
                await dispatch(alertActions.error(error))
                reject(error)
            },
        )
    }
}

function forget(email, lang) {
    return dispatch => {
        userService.forget(email).then(() => {
            if (lang === 'fr') {
                history.push(`/fr/connexion`)
            } else {
                history.push(`/en/login`)
            }
        })
    }
}

const udpatePassword = (
    lastPassword,
    newPassword,
    resolve = () => {},
    reject = () => {},
) => {
    return dispatch => {
        dispatch({
            type: userConstants.UPDATE_PASSWORD,
        })
        userService
            .updatePassword(lastPassword, newPassword)
            .then(async result => {
                const url = window.location.pathname
                const context = getContextFromUrl(url)
                if (result.id) {
                    await dispatch({
                        type: userConstants.PASSWORD_SUCCESS,
                        user: result,
                    })
                    alert(
                        context === 'en'
                            ? 'Updated password'
                            : 'Mot de passe mis à jour',
                    )
                } else {
                    alert(
                        context === 'en'
                            ? 'The old password is not good'
                            : "L'ancien mot de passe n'est pas bon",
                    )
                }
            })
            .catch(async error => {
                reject(error)
                await dispatch({ type: userConstants.PASSWORD_FAILURE, error })
                const url = window.location.pathname
                const context = getContextFromUrl(url)
                alert(
                    context === 'en'
                        ? 'The old password is not good'
                        : "L'ancien mot de passe n'est pas bon",
                )
            })
    }
}

function updateUser(user) {
    return dispatch => {
        const address =
            user.address && user.address.fullText
                ? {
                      fullAddress: user.address.fullText,
                      latitude: user.address.latitude.toString(),
                      longitude: user.address.longitude.toString(),
                  }
                : null

        let body = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            birthday: user.birthday || null,
            contactDetails: user.contactDetails,
            description: user.description,
        }

        if (address) {
            body.address = address
        }

        dispatch({ type: userConstants.UPDATE_USER })
        return requestApi('/users', 'PUT', body).then(
            result => {
                dispatch({
                    type: userConstants.LOGIN_SUCCESS,
                    user: result,
                })
            },
            error => {
                dispatch({ type: userConstants.LOGIN_FAILURE, error })
            },
        )
    }
}

function updateStripeAccountId(accountCode) {
    return dispatch => {
        dispatch({ type: userConstants.STRIPE_ACCOUNT_ID_REQUEST })
        return requestApi(
            '/users/updateStripeAccountId/' + accountCode,
            'PUT',
            {},
        ).then(
            result => {
                dispatch({
                    type: userConstants.STRIPE_ACCOUNT_ID_SUCCESS,
                    user: result,
                })
            },
            error => {
                dispatch({
                    type: userConstants.STRIPE_ACCOUNT_ID_FAILURE,
                    error,
                })
            },
        )
    }
}

function getStripeLoginLink(accountCode) {
    return dispatch => {
        return requestApi(
            '/users/getStripeLoginLink/' + accountCode,
            'GET',
        ).then(
            result => {
                return result
            },
            error => {
                console.error(error)
            },
        )
    }
}

export const userActions = {
    init,
    login,
    logout,
    forget,
    signin,
    updateUser,
    updateStripeAccountId,
    refreshToken,
    udpatePassword,
    showClientPortail,
    getStripeLoginLink,
}
