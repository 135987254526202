const AppRoute = {
    Root: 'routes.root',
    Home: 'routes.home',
    Inqueries: 'routes.inqueries',
    InqueriesParking: 'routes.inqueries.parking',
    Inquery: 'routes.inquery',
    Login: 'routes.login',
    LoginInGroup: 'routes.loginInGroup',
    Register: 'routes.register',
    Profile: 'routes.profile',
    Error404: 'routes.404',
    RentMyParking: 'routes.rent-my-parking',
    Messages: 'routes.messages',
    Forget: 'routes.forget',
    ParkingID: 'routes.parkingid',
    Booking: 'routes.booking',
    PersonnalInfos: 'routes.personnal-infos',
    PaymentInfos: 'routes.payment-infos',
    ParkingInfos: 'routes.parkings-infos',
    BoatInfos: 'routes.boat-infos',
    Blog: 'routes.le-blog',
    ParkingSearchPage: 'routes.search-boat-parking',
    ParkingDepartementPage: 'routes.departement-parking-page',
    ParkingCityPage: 'routes.city-parking-page',
    InsurancePage: 'routes.get-boat-insurance',
    Forum: 'routes.forum',
    InsuranceDetails: 'routes.inqueries.insurance.details',
    InsuranceInqueries: 'routes.inqueries.insurance',
    RepairsInqueries: 'routes.inqueries.repairs',
    ChatBox: 'routes.inqueries.parking.chatbox',
    BoatInfosProfile: 'routes.profile.boat-infos',
    SepaPage: 'routes.sepa',
    PricesPagePart: 'routes.prices.part',
    PricesPageYacht: 'routes.prices.yacht',
    LogBook: {
        Home: 'routes.logbook.home',
        Alert: 'routes.logbook.alerts',
        Fleet: 'routes.logbook.fleet',
        Inventory: 'routes.logbook.inventory',
        Informations: 'routes.logbook.informations',
        ExpensesAndIncome: 'routes.logbook.exps-a-incm',
        Documents: 'routes.logbook.documents',
        Book: 'routes.logbook.book',
        Repair: 'routes.logbook.repairs',
        Pricing: 'routes.logbook.pricing',
        OrderForm: 'routes.logbook.order-form',
        MyUsers: 'routes.logbook.my-users',
    },
    MainSite: 'routes.mainsite',
}

export default AppRoute
