import { combineReducers } from 'redux'

import authentication from './authentication.reducer'
import alert from './alert.reducer'
import filter from './filter.reducer'
import fetch from './fetch.reducer'
import address from './address.reducer'
import post from './post.reducer'
import types from './types.reducer'
import boat from './boat.reducer'
import block from './block.reducer'
import allParkings from './parkings/allParkings.reducer'
import parking from './parkings/parking.reducer'
import inqueries from './inqueries/inqueries.reducer'
import inquery from './inqueries/inquery.reducer'
import companies from './companies/companies.reducer'

const rootReducer = combineReducers({
    authentication,
    alert,
    fetch,
    filter,
    post,
    address,
    types,
    boat,
    block,
    allParkings,
    parking,
    inqueries,
    inquery,
    companies,
})

export default rootReducer
