import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import AppLanguage from '../../constants/AppLanguage'
import { NavLink, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { appStrings } from '../index'

export const LocalizedSwitcher = ({ props }) => {
    const { pathname } = useLocation()
    const { locale, messages } = useIntl()

    const useStyles = makeStyles(theme => {
        return {
            root: {
                color: '#000',
                [theme.breakpoints.up('md')]: {
                    color: '#fff',
                },
            },
            icon: {
                fill: '#000',
                [theme.breakpoints.up('md')]: {
                    fill: '#fff',
                },
            },
            selectMenu: {
                '&:hover': {
                    color: '#000',
                },
                [theme.breakpoints.up('md')]: {
                    color: '#fff',
                },
            },
            flag: {
                height: '18px',
                margin: '0 10px',
            },
            navLink: {
                textDecorationLine: 'none',
                padding: '5px 10px',
                color: 'inherit',
                textAlign: 'center',
                fontSize: '12px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            },
        }
    })

    const classes = useStyles()

    return (
        <FormControl classes={classes.underline}>
            <Select
                value={locale}
                classes={{
                    root: classes.root,
                    icon: classes.icon,
                }}
            >
                {Object.values(AppLanguage).map(element => {
                    let to = getAlterateTo(element)
                    return (
                        <MenuItem
                            value={element}
                            key={element}
                            style={{ padding: 0 }}
                        >
                            <NavLink className={classes.navLink} to={to}>
                                {React.cloneElement(getFlags()[element], {
                                    className: classes.flag,
                                })}
                                {element.toUpperCase()}
                            </NavLink>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )

    function getFlags() {
        return {
            fr: (
                <svg
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <path
                            d="m170.667 90.839h-154.151l-6.032 16.516v289.796c0 9.121 7.395 16.516 16.516 16.516h143.667l19.269-17.283v-280.768z"
                            fill="#5055a0"
                        />
                        <path
                            d="m341.333 413.5h154.151l16.516-8.855v-297.29c0-9.121-7.395-16.516-16.516-16.516h-154.151l-19.269 24.778v280.768z"
                            fill="#e5646e"
                        />
                        <path
                            d="m512 404.645h-183.511l12.844 16.516h154.151c9.121 0 16.516-7.394 16.516-16.516z"
                            fill="#db4655"
                        />
                        <path
                            d="m16.516 355.097v-264.258c-9.121 0-16.516 7.394-16.516 16.516v297.29c0 9.122 7.395 16.516 16.516 16.516h154.151l12.844-16.516h-117.446c-27.365 0-49.549-22.183-49.549-49.548z"
                            fill="#3f3f8f"
                        />
                        <path
                            d="m187.333 413.667h141l13-9.022v-313.806h-170.666v313.806z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m170.667 404.645h170.666v16.516h-170.666z"
                            fill="#e1e1e6"
                        />
                    </g>
                </svg>
            ),
            en: (
                <svg
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <path
                            d="m495.484 90.839h-231.226l-255.258 176.661v146.833h486.484l16.516-9.688v-30.673l-10.75-10.222v-29l10.75-7.967v-47.189l-9.667-9.261v-29.667l9.667-8.262v-47.188l-9.667-8.549v-26l9.667-12.639v-30.673c0-9.122-7.395-16.516-16.516-16.516z"
                            fill="#e5646e"
                        />
                        <path
                            d="m256.333 138.028h255.667v47.189h-255.667z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m256 232.405 8.258 47.189h247.742v-47.189z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m16.516 355.097v-84.097l-16.516 8.594v47.189l7 5.967v31l-7 10.222v30.673c0 9.122 7.395 16.516 16.516 16.516h478.968c9.121 0 16.516-7.395 16.516-16.516h-445.935c-27.365 0-49.549-22.183-49.549-49.548z"
                            fill="#db4655"
                        />
                        <path
                            d="m264.258 90.839h-247.742l-10.516 16.516 10.516 172.239h247.742z"
                            fill="#5055a0"
                        />
                        <path
                            d="m16.516 90.839c-9.121 0-16.516 7.394-16.516 16.516v172.239h16.516z"
                            fill="#3f3f8f"
                        />
                        <path
                            d="m33.113 127.657c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.695 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m33.113 193.722c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.695 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m33.113 259.786c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.695 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m33.113 160.69c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258-.001 4.56-3.695 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m33.113 226.754c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.695 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m231.306 127.657c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m231.306 193.722c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m231.306 259.786c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <g fill="#f2f2f6">
                            <path d="m231.306 160.69c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z" />
                            <path d="m231.306 226.754c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z" />
                            <path d="m82.661 127.657c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z" />
                            <path d="m82.661 193.722c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z" />
                            <path d="m82.661 259.786c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z" />
                        </g>
                        <path
                            d="m82.661 160.69c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258-.001 4.56-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m82.661 226.754c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m57.887 144.173c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258-.001 4.561-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m57.887 210.238c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m57.887 177.206c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m57.887 243.27c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m132.21 127.657c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.695 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m132.21 193.722c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.695 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m132.21 259.786c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.695 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m132.21 160.69c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258-.001 4.56-3.695 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m132.21 226.754c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.695 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m107.435 144.173c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258 0 4.561-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m107.435 210.238c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m107.435 177.206c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m107.435 243.27c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m181.677 127.657c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m181.677 193.722c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m181.677 259.786c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m181.677 160.69c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258-.001 4.56-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m181.677 226.754c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m156.903 144.173c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258-.001 4.561-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m156.903 210.238c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m156.903 177.206c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m156.903 243.27c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m206.532 144.173c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258-.001 4.561-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m206.532 210.238c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m206.532 177.206c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m206.532 243.27c-4.565 0-8.298-3.698-8.298-8.258s3.653-8.258 8.218-8.258h.081c4.565 0 8.258 3.698 8.258 8.258s-3.694 8.258-8.259 8.258z"
                            fill="#fff"
                        />
                        <path
                            d="m3 360.333 17.26 13.639h491.74v-47.189h-495.484z"
                            fill="#f2f2f6"
                        />
                        <path
                            d="m0 326.783v47.189h20.26c-2.401-5.82-3.743-12.189-3.743-18.876v-28.314h-16.517z"
                            fill="#e1e1e6"
                        />
                    </g>
                </svg>
            ),
        }
    }

    function getAlterateTo(language) {
        let routeKey = getCurrentRouteKey()
        if (!routeKey) return `/${language}`
        const route = appStrings[language][routeKey]
        var splitedPathName = pathname.split('/')
        splitedPathName.shift()
        let splitedRoute = route.split('/')
        var finalRoute = ''

        for (let index = 1; index < splitedRoute.length; index++) {
            let regex = /^:\w*$/
            finalRoute +=
                '/' +
                (regex.test(splitedRoute[index])
                    ? splitedPathName[index]
                    : splitedRoute[index])
        }
        return `/${language}` + finalRoute
    }

    function getCurrentRouteKey() {
        const [, route] = pathname.split('/' + locale)

        return Object.keys(messages).find(key => {
            let message = messages[key]
            let regexMessage = new RegExp(
                '^' + message.replace(/:\w*/, '(\\w|-)*') + '(\\/?)$',
            )

            //message === route
            return regexMessage.test(route)
        })
    }
}

export default LocalizedSwitcher
