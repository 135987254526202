import { inqueryConstants } from '../../constants/index'

const initialState = {
    parkingInquery: null,
    insuranceInquery: null,
    parkingAvailable: null,
    creatingInquery: 0,
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case inqueryConstants.CHECK_AVAILABILITY:
            return { ...prevState, parkingAvailable: null }
        case inqueryConstants.CHECK_AVAILABILITY_SUCCESS:
            return { ...prevState, parkingAvailable: action.isAvailable }
        case inqueryConstants.CHECK_AVAILABILITY_FAILED:
            return { ...prevState, parkingAvailable: null }
        case inqueryConstants.RESET_AVAILABILITY:
            return { ...prevState, parkingAvailable: null }
        case inqueryConstants.CREATE_INQUERY_PARKING:
        case inqueryConstants.CREATE_INQUERY_INSURANCE:
            return {
                ...prevState,
                creatingInquery: prevState.creatingInquery + 1,
            }
        case inqueryConstants.CREATE_INQUERY_PARKING_SUCCESS:
            return {
                ...prevState,
                parkingInquery: action.inquery,
                creatingInquery: prevState.creatingInquery - 1,
            }
        case inqueryConstants.CREATE_INQUERY_INSURANCE_SUCCESS:
            return {
                ...prevState,
                insuranceInquery: action.inquery,
                creatingInquery: prevState.creatingInquery - 1,
            }
        case inqueryConstants.CREATE_INQUERY_PARKING_FAILED:
        case inqueryConstants.CREATE_INQUERY_INSURANCE_FAILED:
            return { ...prevState, creatingInquery: -1 }
        case inqueryConstants.REQUEST_INQUERY:
        case inqueryConstants.ACCEPT_INQUERY:
        case inqueryConstants.REFUSE_INQUERY:
        case inqueryConstants.CANCEL_INQUERY:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case inqueryConstants.REQUEST_INQUERY_SUCCESS:
        case inqueryConstants.ACCEPT_INQUERY_SUCCESS:
        case inqueryConstants.REFUSE_INQUERY_SUCCESS:
        case inqueryConstants.CANCEL_INQUERY_SUCCESS:
            return {
                ...prevState,
                parkingInquery: action.parkingInquery,
                loading: prevState.loading - 1,
            }
        case inqueryConstants.REQUEST_INQUERY_FAILED:
        case inqueryConstants.ACCEPT_INQUERY_FAILED:
        case inqueryConstants.REFUSE_INQUERY_FAILED:
        case inqueryConstants.CANCEL_INQUERY_FAILED:
            return {
                ...prevState,
                parkingInquery: null,
                loading: prevState.loading - 1,
                error: action.error,
            }
        case inqueryConstants.SET_INQUERY:
            return {
                ...prevState,
                parkingInquery: action.parkingInquery,
            }
        case inqueryConstants.UNSET_INQUERY:
            return {
                ...prevState,
                parkingInquery: null,
            }
        case inqueryConstants.GET_SESSION_URL:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case inqueryConstants.GET_SESSION_URL_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case inqueryConstants.GET_SESSION_URL_FAILED:
            return {
                ...prevState,
                error: action.error,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
