import { companiesConstants } from '../../constants/index'
import { requestApi } from '../../services/api.service'

const getVatType = () => {
    return dispatch => {
        dispatch({
            type: companiesConstants.GET_VAT_TYPES,
        })
        requestApi(`/vat`, 'GET').then(
            result => {
                dispatch({
                    type: companiesConstants.GET_VAT_TYPES_SUCCESS,
                    vatTypes: result,
                })
            },
            error => {
                dispatch({ type: companiesConstants.GET_VAT_TYPES_FAILED })
            },
        )
    }
}

const getUserCompany = () => {
    return dispatch => {
        dispatch({
            type: companiesConstants.REQUEST_GET_COMPANY,
        })
        requestApi(`/companies/user`, 'GET').then(
            result => {
                dispatch({
                    type: companiesConstants.REQUEST_GET_COMPANY_SUCCESS,
                    userCompany: result,
                })
            },
            error => {
                dispatch({
                    type: companiesConstants.REQUEST_GET_COMPANY_FAILED,
                })
            },
        )
    }
}

const getSirenByCompanyName = companyName => {
    return dispatch => {
        dispatch({
            type: companiesConstants.REQUEST_GET_SIREN_BY_COMPANY,
        })
        requestApi(`/companies/siren/` + companyName, 'GET').then(
            result => {
                dispatch({
                    type:
                        companiesConstants.REQUEST_GET_SIREN_BY_COMPANY_SUCCESS,
                    companyInfo: result,
                })
            },
            error => {
                dispatch({
                    type:
                        companiesConstants.REQUEST_GET_SIREN_BY_COMPANY_FAILED,
                })
            },
        )
    }
}

const _setCompanyData = (
    name,
    siren,
    codeApe,
    phone,
    vatId,
    siret,
    effectiveBeneficiary,
    legalRepresentativeFirstName,
    legalRepresentativeLastName,
    legalRepresentativeBirthday,
    legalRepresentativeBirthPlace,
    legalRepresentativeNationality,
    legalForm,
    address,
) => {
    let data = {
        companyName: name,
        siren: siren,
        apeCode: codeApe,
    }
    if (vatId) data.vat = { id: vatId }
    if (phone)
        data.contactDetails = [{ contact: phone, contactType: { id: 1 } }]
    if (siret) data.siret = siret
    if (effectiveBeneficiary) data.effectiveBeneficiary = effectiveBeneficiary
    if (legalRepresentativeFirstName)
        data.legalRepresentativeFirstName = legalRepresentativeFirstName
    if (legalRepresentativeLastName)
        data.legalRepresentativeLastName = legalRepresentativeLastName
    if (legalRepresentativeBirthday)
        data.legalRepresentativeBirthday = legalRepresentativeBirthday
    if (legalRepresentativeBirthPlace)
        data.legalRepresentativeBirthPlace = legalRepresentativeBirthPlace
    if (legalRepresentativeNationality)
        data.legalRepresentativeNationality = legalRepresentativeNationality
    if (legalForm) data.legalForm = legalForm
    if (address) data.address = address
    return data
}

const createCompany = (
    name,
    siren,
    codeApe,
    phone,
    vatId,
    siret,
    effectiveBeneficiary,
    legalRepresentativeFirstName,
    legalRepresentativeLastName,
    legalRepresentativeBirthday,
    legalRepresentativeBirthPlace,
    legalRepresentativeNationality,
    legalForm,
    address,
    { callback = json => {} },
) => {
    return dispatch => {
        dispatch({
            type: companiesConstants.REQUEST_CREATE_COMPANY,
        })
        const data = _setCompanyData(
            name,
            siren,
            codeApe,
            phone,
            vatId,
            siret,
            effectiveBeneficiary,
            legalRepresentativeFirstName,
            legalRepresentativeLastName,
            legalRepresentativeBirthday,
            legalRepresentativeBirthPlace,
            legalRepresentativeNationality,
            legalForm,
            address,
        )

        requestApi(`/users/company`, 'POST', data).then(
            result => {
                dispatch({
                    type: companiesConstants.REQUEST_CREATE_COMPANY_SUCCESS,
                    userCompany: result,
                })
                if (callback) callback(result)
            },
            error => {
                dispatch({
                    type: companiesConstants.REQUEST_CREATE_COMPANY_FAILED,
                    error: error,
                })
            },
        )
    }
}

const updateCompany = (
    companyId,
    name,
    siren,
    codeApe,
    phone,
    vatId,
    siret,
    effectiveBeneficiary,
    legalRepresentativeFirstName,
    legalRepresentativeLastName,
    legalRepresentativeBirthday,
    legalRepresentativeBirthPlace,
    legalRepresentativeNationality,
    legalForm,
    address,
    { callback = json => {} },
) => {
    return dispatch => {
        dispatch({
            type: companiesConstants.REQUEST_UPDATE_COMPANY,
        })
        const data = _setCompanyData(
            name,
            siren,
            codeApe,
            phone,
            vatId,
            siret,
            effectiveBeneficiary,
            legalRepresentativeFirstName,
            legalRepresentativeLastName,
            legalRepresentativeBirthday,
            legalRepresentativeBirthPlace,
            legalRepresentativeNationality,
            legalForm,
            address,
        )

        requestApi(`/companies/${companyId}`, 'PUT', data).then(
            result => {
                dispatch({
                    type: companiesConstants.REQUEST_UPDATE_COMPANY_SUCCESS,
                    userCompany: result,
                })
                if (callback) callback(result)
            },
            error => {
                dispatch({
                    type: companiesConstants.REQUEST_UPDATE_COMPANY_FAILED,
                    error: error,
                })
            },
        )
    }
}

const getAllInfoCompanyBySiret = siret => {
    return requestApi(`/companies/siret/${siret}`, 'GET').then(
        result => result,
        error => error,
    )
}

export const companiesActions = {
    getVatType,
    createCompany,
    updateCompany,
    getUserCompany,
    getSirenByCompanyName,
    getAllInfoCompanyBySiret,
}
