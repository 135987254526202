import { boatsConstants } from '../constants/index'

const initialState = {
    boats: null,
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case boatsConstants.REQUEST_BOATS:
            return {
                ...prevState,
                boats: null,
                loading: prevState.loading + 1,
            }
        case boatsConstants.POST_BOATS:
            return {
                ...prevState,
                boats: null,
                loading: prevState.loading + 1,
            }
        case boatsConstants.BOATS_SUCCESS:
            return {
                ...prevState,
                boats: action.boats,
                loading: prevState.loading - 1,
            }
        case boatsConstants.POST_BOATS_TO_GROUP:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case boatsConstants.BOATS_TO_GROUP_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case boatsConstants.BOATS_UPDATE_SUCCESS:
            const updateBoats = prevState.boats.map(boat => {
                if (boat.id === action.boat.id) {
                    return action.boat
                }
                return boat
            })
            return {
                ...prevState,
                boats: updateBoats,
            }
        case boatsConstants.BOATS_FAILURE:
            return {
                ...prevState,
                boats: null,
                loading: prevState.loading - 1,
            }
        case boatsConstants.BOATS_TO_GROUP_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case boatsConstants.RESET_BOATS:
            return initialState
        default:
            return prevState
    }
}
