export const boatsConstants = {
    REQUEST_BOATS: 'REQUEST_BOATS',
    POST_BOATS: 'POST_BOATS',
    BOATS_SUCCESS: 'BOATS_SUCCESS',
    BOATS_FAILURE: 'BOATS_FAILURE',
    POST_BOATS_TO_GROUP: 'POST_BOATS_TO_GROUP',
    BOATS_TO_GROUP_SUCCESS: 'BOATS_TO_GROUP_SUCCESS',
    BOATS_TO_GROUP_FAILURE: 'BOATS_TO_GROUP_FAILURE',
    BOATS_UPDATE_SUCCESS: 'BOATS_UPDATE_SUCCESS',
    RESET_BOATS: `RESET_BOATS`,
}
