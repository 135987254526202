export const fr = {
    'routes.root': '/',
    'routes.home': '/accueil',
    'routes.inqueries': '/mes-demandes',
    'routes.inqueries.insurance': '/mes-demandes/assurance',
    'routes.inqueries.insurance.details': '/mes-demandes/assurance/details',
    'routes.inqueries.repairs': '/mes-demandes/reparations',
    'routes.inqueries.parking': '/mes-demandes/parking',
    'routes.inqueries.parking.chatbox': '/mes-demandes/parking/messagerie',
    'routes.inquery': '/ma-demande',
    'routes.login': '/connexion',
    'routes.loginInGroup': '/connexion/:id',
    'routes.register': '/register',
    'routes.profile': '/mon-profil',
    'routes.404': '/erreur-404',
    'routes.rent-my-parking': '/louer-mon-emplacement',
    'routes.messages': '/messages',
    'routes.forget': '/forget',
    'routes.parkingid': '/emplacement/:id',
    'routes.booking': '/reservation',
    'routes.personnal-infos': '/mes-informations-personnelles',
    'routes.payment-infos': '/payment-infos',
    'routes.parkings-infos': '/mes-emplacements',
    'routes.boat-infos': '/mon-bateau',
    'routes.le-blog': '/le-blog',
    'routes.forum': '/forum',
    'routes.search-boat-parking': '/stockage-sans-engagement',
    'routes.get-boat-insurance': '/trouver-assurance-bateau',
    'routes.profile.boat-infos': '/mon-bateau',
    'routes.sepa': '/paiement-sepa',
    'routes.prices': '/nos-offres',
    'routes.prices.part': '/nos-offres/part',
    'routes.prices.yacht': '/nos-offres/yacht',
    'routes.logbook.home': '/journal-de-bord',
    'routes.logbook.alerts': '/journal-de-bord/mes-alertes',
    'routes.logbook.inventory': '/journal-de-bord/mon-inventaire',
    'routes.logbook.fleet': '/journal-de-bord/ma-flotte',
    'routes.logbook.repairs': '/journal-de-bord/mes-entretiens',
    'routes.logbook.informations': '/journal-de-bord/mon-bateau',
    'routes.logbook.exps-a-incm': '/journal-de-bord/mes-depenses-et-revenus',
    'routes.logbook.documents': '/journal-de-bord/mes-documents',
    'routes.logbook.book': '/journal-de-bord/mon-boaton-book',
    'routes.logbook.pricing': '/journal-de-bord/tarifs',
    'routes.logbook.order-form': '/journal-de-bord/bon-de-commande',
    'routes.logbook.my-users': '/journal-de-bord/mes-utilisateurs',
    'routes.mainsite': '/site-principal',
}
