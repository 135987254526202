import { parkingConstants } from '../../constants/index'

const initialState = {
    id: -1,
    width: -1.0,
    length: -1.0,
    height: -1.0,
    title: null,
    description: null,
    minimumDuration: null,
    totalView: -1,
    userId: -1,
    companyId: -1,
    activationStatus: null,
    parkingPrice: null,
    cancellationType: null,
    address: null,
    platformType: null,
    files: [],
    parkingOptionTypes: [],
    timestamp: null,
    loading: 0,
    error: null,
    delimitedDates: [],
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case parkingConstants.START_UPDATE_PARKING:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case parkingConstants.SET_PARKING_SUCCESS:
            return {
                ...prevState,
                id: action.parking.id,
                width: action.parking.width,
                length: action.parking.length,
                height: action.parking.height,
                title: action.parking.titleBoaton, //si site stockon mettre stockon
                description: action.parking.descBoaton, //si site stockon mettre stockon
                minimumDuration: action.parking.minimumDuration,
                totalView: action.parking.totalView,
                userId: action.parking.userId,
                companyId: action.parking.companyId,
                vatId: action.parking.vatId,
                activationStatus: action.parking.activationStatus,
                parkingPrice: action.parking.parkingPrice,
                cancellationType: action.parking.cancellationType,
                address: action.parking.address,
                platformType: action.parking.platformType,
                files: action.parking.files,
                parkingOptionTypes: action.parking.parkingOptionTypes,
                timestamp: action.parking.timestamp,
                delimitedDates: action.parking.delimitedDates,
                loading:
                    prevState.loading > 0
                        ? prevState.loading - 1
                        : prevState.loading,
            }
        case parkingConstants.SET_PARKING_FAILURE:
            return {
                ...initialState,
                loading:
                    prevState.loading > 0
                        ? prevState.loading - 1
                        : prevState.loading,
                error: action.error,
            }
        case parkingConstants.RESET_PARKING:
            return initialState
        default:
            return prevState
    }
}
