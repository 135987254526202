import { allParkingsConstants } from '../../constants/index'

const initialState = {
    parkings: [],
    personalParkings: [],
    reservedParkings: [],
    loading: 0,
    error: null,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case allParkingsConstants.REQUEST_PARKINGS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case allParkingsConstants.REQUEST_PARKINGS_SUCCESS:
            return {
                ...prevState,
                parkings: action.parkings,
                loading: prevState.loading - 1,
            }
        case allParkingsConstants.REQUEST_PARKINGS_FAILURE:
            return {
                ...prevState,
                parkings: [],
                loading: prevState.loading - 1,
                error: action.error,
            }
        case allParkingsConstants.REQUEST_PERSONAL_PARKINGS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case allParkingsConstants.REQUEST_PERSONAL_PARKINGS_SUCCESS:
            return {
                ...prevState,
                personalParkings: action.personalParkings,
                loading: prevState.loading - 1,
            }
        case allParkingsConstants.REQUEST_PERSONAL_PARKINGS_FAILURE:
            return {
                ...prevState,
                personalParkings: [],
                loading: prevState.loading - 1,
                error: action.error,
            }
        case allParkingsConstants.REQUEST_RESERVED_PARKINGS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case allParkingsConstants.REQUEST_RESERVED_PARKINGS_SUCCESS:
            return {
                ...prevState,
                reservedParkings: action.parkings,
                loading: prevState.loading - 1,
            }
        case allParkingsConstants.REQUEST_RESERVED_PARKINGS_FAILURE:
            return {
                ...prevState,
                reservedParkings: [],
                loading: prevState.loading - 1,
                error: action.error,
            }
        case allParkingsConstants.START_UPDATE_PARKING_TOTAL_VIEW:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case allParkingsConstants.UPDATE_PARKING_TOTAL_VIEW_SUCCESS:
            return {
                ...prevState,
                parkings: prevState.parkings
                    ? prevState.parkings.map(parking => {
                          if (parking.id === action.parkingUpdated.id)
                              return action.parkingUpdated
                          return parking
                      })
                    : prevState.parkings,
                loading: prevState.loading - 1,
            }
        case allParkingsConstants.UPDATE_PARKING_TOTAL_VIEW_FAILURE:
            return {
                ...prevState,
                parkings: [],
                loading: prevState.loading - 1,
                error: action.error,
            }
        case allParkingsConstants.CHANGE_STATUS_PARKING:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case allParkingsConstants.CHANGE_STATUS_PARKING_SUCCESS:
            return {
                ...prevState,
                personalParkings: prevState.personalParkings
                    ? prevState.personalParkings.map(parking => {
                          if (parking.id === action.parkingUpdated.id)
                              return action.parkingUpdated
                          return parking
                      })
                    : prevState.parkings,
                loading: prevState.loading - 1,
            }
        case allParkingsConstants.CHANGE_STATUS_PARKING_FAILURE:
            return {
                ...prevState,
                personalParkings: [],
                loading: prevState.loading - 1,
                error: action.error,
            }
        default:
            return prevState
    }
}
