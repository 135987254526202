import prevu_bob from '../images/prevu_bob.png'

const AppMeta = {
    fr: {
        'routes.root': {
            title:
                'StockOn.fr I Hivernage, costockage, stockage de camping cars et caravanes',
            description:
                'Costockage, hivernage, location pour camping cars, vans et caravanes, stockage intérieur et extérieur pas cher ✅ simple ✅ sans engagement  ✅ 100% sécurisé',
            type: '',
            image: '',
        },
    },
    en: {
        'routes.root': {
            title:
                'StockOn.fr I Winter storage, costorage, storage of motorhomes and caravans',
            description:
                'Storage sharing, winter storage, winterizing for motorhomes, vans and caravans, cheap indoor and outdoor storage ✅ simple ✅ no obligation ✅ 100% secure',
            type: '',
            image: '',
        },
    },
}

export default AppMeta
