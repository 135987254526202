import React, { PureComponent, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Router } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { PrivateRoute } from './components/common/PrivateRoute'

import { history } from './helpers/index'
import { alertActions, userActions } from './actions/index'

import theme from './helpers/customTheme'
import CircularProgress from '@material-ui/core/CircularProgress'

import AppLanguage from './constants/AppLanguage'
import AppRoute from './constants/AppRoute'
import {
    LocalizedRouter,
    MultiLangualSwitch,
    appStrings,
} from './languages/index'
import { GTM_ID } from './services/config.service'
import TagManager from 'react-gtm-module'

const OrderDetailsPage = lazy(() =>
    import('./components/OrderDetailsPage/OrderDetailsPage'),
)
const LoginPage = lazy(() => import('./components/LoginPage/LoginPage'))
const HomePage = lazy(() => import('./components/HomePage/HomePage'))
const ProfilePage = lazy(() => import('./components/ProfilePage/ProfilePage'))
const ForgetPage = lazy(() => import('./components/ForgetPage/ForgetPage'))
const ParkingPage = lazy(() => import('./components/ParkingPage/ParkingPage'))
const BookingPage = lazy(() => import('./components/BookingPage/BookingPage'))
const EditParkingPage = lazy(() =>
    import('./components/EditParkingPage/EditParkingPage'),
)
const MessagePage = lazy(() => import('./components/MessagePage/MessagePage'))
const DuplicateParkingPage = lazy(() =>
    import('./components/DuplicateParkingPage/DuplicateParkingPage'),
)
const ParkingSearchPage = lazy(() =>
    import('./components/ParkingSearchPage/ParkingSearchPage'),
)
const RentParkingPage = lazy(() =>
    import('./components/RentParkingPage/RentParkingPage'),
)

// Static Pages
const Error404StaticPage = lazy(() =>
    import('./components/StaticPages/Error404Page'),
)

const tagManagerArgs = {
    gtmId: GTM_ID,
}

TagManager.initialize(tagManagerArgs)

class App extends PureComponent {
    constructor(props) {
        super(props)

        const { dispatch } = this.props
        dispatch(userActions.init())

        history.listen(() => {
            dispatch(alertActions.clear())
        })
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <LocalizedRouter
                    RouterComponent={Router}
                    languages={AppLanguage}
                    appStrings={appStrings}
                    history={history}
                >
                    <Suspense
                        fallback={
                            <div
                                style={{
                                    width: `100%`,
                                    height: `100vh`,
                                    display: `flex`,
                                    justifyContent: `center`,
                                    alignItems: `center`,
                                }}
                            >
                                <CircularProgress color="inherit" />
                            </div>
                        }
                    >
                        <MultiLangualSwitch
                            errorRedirection={AppRoute.Error404}
                        >
                            <Route
                                exact
                                path={'/ma-demande/:id?'}
                                component={OrderDetailsPage}
                            />
                            <Route
                                exact
                                path={'/my-request/:id?'}
                                component={OrderDetailsPage}
                            />
                            <Route
                                route={AppRoute.Login}
                                component={LoginPage}
                            />
                            <Route
                                route={AppRoute.LoginInGroup}
                                component={LoginPage}
                            />
                            <Route
                                route={AppRoute.Register}
                                component={() => <LoginPage register={true} />}
                            />
                            <Route
                                route={AppRoute.Forget}
                                component={ForgetPage}
                            />
                            <PrivateRoute
                                route={AppRoute.Messages}
                                component={MessagePage}
                            />
                            <PrivateRoute
                                route={AppRoute.Profile}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.PersonnalInfos}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.PaymentInfos}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.ParkingInfos}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.BoatInfos}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.BoatInfosProfile}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.Inqueries}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.InsuranceInqueries}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.InsuranceDetails}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.ChatBox}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.RepairsInqueries}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.InqueriesParking}
                                component={ProfilePage}
                            />
                            <PrivateRoute
                                route={AppRoute.Messages}
                                component={ProfilePage}
                            />
                            <Route
                                exact
                                route={AppRoute.Root}
                                component={HomePage}
                            ></Route>

                            <Route
                                exact
                                route={AppRoute.Home}
                                component={HomePage}
                            ></Route>

                            {/* PARKING */}
                            <Route
                                route={AppRoute.ParkingID}
                                component={ParkingPage}
                            />
                            <PrivateRoute
                                route={AppRoute.Booking}
                                component={BookingPage}
                            />

                            <Route
                                exact
                                route={AppRoute.Root}
                                component={HomePage}
                            />

                            <Route exact route={AppRoute.Home}>
                                <Redirect to="/" />
                            </Route>

                            <Route
                                exact
                                path="/cgv"
                                title="cgv"
                                component={() => {
                                    window.location =
                                        'https://www.info.boaton.fr/cgv'
                                    return null
                                }}
                            />

                            <Route path="/search/.hash.horsbord/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.achat/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.jetski/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.moteur/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.inboard/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.voile/">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/search/.hash.bateau/">
                                <Redirect to="/" />
                            </Route>

                            {/* VOILA LES SOFTS 404 NE FONCTIONNANT PAS */}
                            <Route path="/search-boat-parking#/">
                                <Redirect to="/" />
                            </Route>
                            <Route
                                route={AppRoute.ParkingSearchPage}
                                component={ParkingSearchPage}
                            />
                            <Route
                                exact
                                route={AppRoute.RentMyParking}
                                component={RentParkingPage}
                            />
                            <PrivateRoute
                                exact
                                path="/edit-my-parking/:id"
                                component={EditParkingPage}
                            />
                            <PrivateRoute
                                exact
                                path="/duplicate-my-parking/:id"
                                component={DuplicateParkingPage}
                            />
                            <Route
                                path="/le-blog/"
                                title="blog"
                                component={value => {
                                    window.location =
                                        'https://www.info.boaton.fr/le-blog/'
                                    return null
                                }}
                            />
                            <Route
                                path="/forum/"
                                title="forum"
                                component={value => {
                                    window.location =
                                        'https://www.info.boaton.fr/forum/'
                                    return null
                                }}
                            />
                            <Route
                                path="/post/"
                                title="post"
                                component={value => {
                                    window.location =
                                        'https://www.info.boaton.fr/post/'
                                    return null
                                }}
                            />

                            <Route
                                path="*"
                                component={Error404StaticPage}
                                status={404}
                            />
                        </MultiLangualSwitch>
                    </Suspense>
                </LocalizedRouter>
            </MuiThemeProvider>
        )
    }
}

export default connect()(App)
