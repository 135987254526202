import { blockConstants } from '../constants/index'

const initialState = {
    selectedGroup: null,
    error: false,
}

export default function postDatasReducer(prevState = initialState, action) {
    switch (action.type) {
        case blockConstants.REQUEST_GROUP:
            return {
                ...prevState,
                selectedGroup: null,
                error: false,
            }
        case blockConstants.REQUEST_GROUP_SUCCESS:
            return {
                ...prevState,
                selectedGroup: action.selectedGroup,
            }
        case blockConstants.REQUEST_GROUP_FAILURE:
            return {
                ...prevState,
                selectedGroup: null,
                error: true,
            }
        case blockConstants.PERSONNAL_GROUP:
            return {
                ...prevState,
                selectedGroup: null,
            }
        default:
            return prevState
    }
}
