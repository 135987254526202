export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'USERS_LOGOUT',
    UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
    REQUEST_USER: 'REQUEST_USER',

    REFRESH_TOKEN: 'REFRESH_TOKEN',
    TOKEN_REFRESH_SUCCESS: 'TOKEN_REFRESH_SUCCESS',
    TOKEN_REFRESH_FAILURE: 'TOKEN_REFRESH_FAILURE',

    SUBSCRIBE_REQUEST: 'USERS_LOGBOOK_REQUEST',
    SUBSCRIBE_SUCCESS: 'USERS_LOGBOOK_SUBSCRIBED',
    SUBSCRIBE_FAILURE: 'USERS_LOGBOOK_FAILURE',

    STRIPE_ACCOUNT_ID_REQUEST: 'STRIPE_ACCOUNT_ID_REQUEST',
    STRIPE_ACCOUNT_ID_SUCCESS: 'STRIPE_ACCOUNT_ID_SUBSCRIBED',
    STRIPE_ACCOUNT_ID_FAILURE: 'STRIPE_ACCOUNT_ID_FAILURE',

    UPDATE_USER: 'UPDATE_USER',

    SUBSCRIPTIONS_REQUEST: 'SUBSCRIPTIONS_REQUEST',
    SUBSCRIPTIONS_SUCCESS: 'SUBSCRIPTIONS_SUCCESS',
    SUBSCRIPTIONS_FAILURE: 'SUBSCRIPTIONS_FAILURE',

    UPDATE_PASSWORD: 'UPDATE_PASSWORD',
    PASSWORD_SUCCESS: 'PASSWORD_SUCCESS',
    PASSWORD_FAILURE: 'PASSWORD_FAILURE',

    STRIPE_CUSTOMER_PORTAIL: 'STRIPE_CUSTOMER_PORTAIL',
    STRIPE_CUSTOMER_PORTAIL_SUCCESS: 'STRIPE_CUSTOMER_PORTAIL_SUCCESS',
    STRIPE_CUSTOMER_PORTAIL_FAILURE: 'STRIPE_CUSTOMER_PORTAIL_FAILURE',

    START_BOATS_UPDATE: `START_BOATS_UPDATE`,
    BOATS_UPDATED: `BOATS_UPDATED`,
    BOATS_UPDATE_FAILED: `BOATS_UPDATE_FAILED`,
}
