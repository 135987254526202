import { companiesConstants } from '../../constants/index'

const initialState = {
    vatTypes: null,
    userCompany: null,
    companyInfo: [],
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case companiesConstants.GET_VAT_TYPES:
            return { ...prevState, vatTypes: null }
        case companiesConstants.GET_VAT_TYPES_SUCCESS:
            return {
                ...prevState,
                vatTypes: action.vatTypes,
            }
        case companiesConstants.REQUEST_CREATE_COMPANY:
        case companiesConstants.REQUEST_UPDATE_COMPANY:
            return { ...prevState, userCompany: null }
        case companiesConstants.REQUEST_CREATE_COMPANY_SUCCESS:
        case companiesConstants.REQUEST_UPDATE_COMPANY_SUCCESS:
            return {
                ...prevState,
                userCompany: action.userCompany,
            }
        case companiesConstants.REQUEST_GET_COMPANY:
            return { ...prevState, userCompany: null }
        case companiesConstants.REQUEST_GET_COMPANY_SUCCESS:
            return {
                ...prevState,
                userCompany: action.userCompany,
            }
        case companiesConstants.REQUEST_GET_SIREN_BY_COMPANY:
            return { ...prevState, companyInfo: [] }
        case companiesConstants.REQUEST_GET_SIREN_BY_COMPANY_SUCCESS:
            return {
                ...prevState,
                companyInfo: action.companyInfo,
            }
        default:
            return prevState
    }
}
