import { requestApi } from '../services/api.service'
import { addressConstants } from '../constants/index'

export const FETCH_DATAS_ADDRESS_BEGIN = 'FETCH_DATAS_ADDRESS_BEGIN'
export const FETCH_DATAS_ADDRESS_SUCCESS = 'FETCH_DATAS_ADDRESS_SUCCESS'
export const FETCH_DATAS_ADDRESS_ERROR = 'FETCH_DATAS_ADDRESS_ERROR'

const requestAddressData = (query, fetchCallback = json => {}) => {
    return dispatch => {
        dispatch({
            type: addressConstants.REQUEST_AUTOCOMPLET_ADDRESS,
        })
        return requestApi(
            '/addresses/autocompletion/address/' + query,
            'GET',
        ).then(
            result => {
                dispatch({
                    type: addressConstants.REQUEST_AUTOCOMPLET_SUCCESS,
                    autoComplet: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: addressConstants.REQUEST_AUTOCOMPLET_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestCityData = (query, fetchCallback = json => {}) => {
    return dispatch => {
        dispatch({
            type: addressConstants.REQUEST_AUTOCOMPLET_CITY,
        })
        return requestApi(
            '/addresses/autocompletion/city/' + query,
            'GET',
        ).then(
            result => {
                dispatch({
                    type: addressConstants.REQUEST_AUTOCOMPLET_SUCCESS,
                    autoComplet: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: addressConstants.REQUEST_AUTOCOMPLET_FAILURE,
                    error,
                })
            },
        )
    }
}

const getGeoJson = (city, zipcode) => {
    return dispatch => {
        dispatch({
            type: addressConstants.REQUEST_GEOJSON_CITY,
        })
        const url = zipcode
            ? '/addresses/geojson/' + city + '/' + zipcode
            : '/addresses/geojson/' + city
        return requestApi(url, 'GET').then(
            result => {
                dispatch({
                    type: addressConstants.REQUEST_GEOJSON_CITY_SUCCESS,
                    geojson: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: addressConstants.REQUEST_GEOJSON_CITY_FAILURE,
                    error,
                })
            },
        )
    }
}

export const addressActions = {
    requestAddressData,
    getGeoJson,
    requestCityData,
}
